import styled from 'styled-components';

export const Body = styled.div`
  padding: 40px 110px;
  padding-bottom: 30px;
  width: 860px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding: 35px;
  }
`;

export const Header3 = styled.h2`
  color: #48b4f1;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 35px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;
export const ParaText = styled.div`
  color: #666;
  white-space: pre-wrap;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
  font-size: 20px;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  @media (max-width: 767px) {
    line-height: 22px;
  }
  b {
    font-family: 'Helvetica-Condensed', arial, sans-serif;
  }
  code {
    text-decoration: none;
    transition: 0.25s ease-out;
    text-align: center;
    margin: 0 0 35px 0;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    color: #00539f;
    font-size: 22px;
  }
`;

export const Header2 = styled.h2`
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  font-weight: normal !important;
  color: #037ec4;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.02em;
  margin-bottom: 35px;
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

export const Header = styled.h1`
  font-size: 38px;
  line-height: 42px;
  padding: 15px 0 0 0;
  margin: 0 0 25px 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-weight: normal !important;
  color: #00539f;
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 38px;
    padding-top: 20px;
  }
`;

export const SuperScriptStyle = styled.sup`
  vertical-align: top;
  font-size: 18px;
  line-height: 30px;
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  text-align: center;
  margin: 0 0 35px 0;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  cursor: pointer;
  color: #00a6e8;
  code {
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    cursor: pointer;
    color: #ffca05;
    font-size: 22px;
    :hover {
      cursor: pointer;
      color: #00539f;
    }
  }
`;
export const Header6 = styled.h6`
  font-family: 'MrAlex', arial, sans-serif;
  font-weight: normal !important;
  color: #00539f;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.02em;
  margin-bottom: 35px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 20px;
  }
`;
